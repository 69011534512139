import { environment } from '../../environments/environment';

export const apiEndPoints = {
  reportsEndPoints: {
    objectives: `${environment.miUrl}objective`,
    groups: `${environment.miUrl}groups`,
    reports: `${environment.miUrl}reports`,
    filterDataList: `${environment.miUrl}filter-data-list`,
    filterCaseCaddingList: `${environment.miUrl}cascading-filter-data`,
    unitOccupancyReport: `${environment.miReportUrl}unit-occupancy-report`,
    totalInventoryReport: `${environment.miReportUrl}total-inventory-report`,
    investmentPropertyReport: `${environment.miReportUrl}investment-property-report`,
    investmentUnitReport: `${environment.miReportUrl}investment-unit-report`,
    propertyReport: `${environment.miReportUrl}property-report`,
    unitTypeReport: `${environment.miReportUrl}unit-type-report`,
    unitGradeReport: `${environment.miReportUrl}unit-grade-report`,
    searchMaster: `search-master`,
    unitOccupancyLeaseReport: `${environment.miReportUrl}unit-occupancy-lease-report`,
    unitOccupancyTypeReport: `${environment.miReportUrl}unit-occupancy-type-report`,
    yearWiseOccupancyLeaseReport: `${environment.miReportUrl}year-wise-occupancy-lease-report`,
    unitTypeOccupancyLeaseReport: `${environment.miReportUrl}unit-type-occupancy-lease-report`,
    typeGradePropertyProfilingReport: `${environment.miReportUrl}type-grade-property-profiling-report`,
    typePropertyProfilingReport: `${environment.miReportUrl}type-property-profiling-report`,
    gradeFurnishedPropertyProfilingReport: `${environment.miReportUrl}grade-furnished-property-profiling-report`,
    amenitiesPropertyProfilingReport: `${environment.miReportUrl}amenities-property-profiling-report`,
    totalPropertyProfilingReport: `${environment.miReportUrl}property-profiling-total-report`,
    totalPopulationReport: `${environment.miReportUrl}population-total-report`,
    populationTrendline: `${environment.miReportUrl}population-trendline-report`,
    populationYearWise: `${environment.miReportUrl}population-year-wise-report`,
    populationAgeWise: `${environment.miReportUrl}population-age-wise-report`,
    mallInventoryTotal: `${environment.miReportUrl}mall-inventory-total-report`,
    mallInventoryYearlyUnits: `${environment.miReportUrl}mall-inventory-yearly-units-report`,
    mallInventoryOccupancy: `${environment.miReportUrl}mall-inventory-yearly-occupancy-report`,
    mallInventoryIncremental: `${environment.miReportUrl}mall-inventory-yearly-incremental-report`,
    mallInventoryNeighTrend: `${environment.miReportUrl}mall-inventory-neigh-trend-report`,
    mallProfilingTotal: `${environment.miReportUrl}mall-profiling-total-report`,
    mallProfilingFloorWise: `${environment.miReportUrl}mall-profiling-floor-wise-report`,
    floorDetails: `${environment.miReportUrl}floor-details`,
    unitDetails: `${environment.miReportUrl}unit-details`,
    mallTentantTotal: `${environment.miReportUrl}mall-tenant-total-report`,
    mallTentantShareCategory: `${environment.miReportUrl}mall-tenant-share-category-report`,
    mallTentantArea: `${environment.miReportUrl}mall-tenant-trend-area-report`,
    officeOccupancyTotal: `${environment.miReportUrl}office-occupancy-total-report`,
    officeInventory: `${environment.miReportUrl}office-inventory-report`,
    officeOccupiedLease: `${environment.miReportUrl}office-occupied-average-lease-report`,
    officeNeighAverage: `${environment.miReportUrl}office-neigh-average-report`,
    officePropertyGrade: `${environment.miReportUrl}office-property-grade-unit-lease-report`,
    officeSpaceTotal: `${environment.miReportUrl}office-space-total-report`,
    officeSpaceInventoryTrend: `${environment.miReportUrl}office-space-inventory-trend-report`,
    officeSpaceNeighLease: `${environment.miReportUrl}office-space-neigh-lease-report`,
    officeSpaceInventory: `${environment.miReportUrl}office-inventory-report`,
    officeProfilingTotal: `${environment.miReportUrl}office-profiling-total-report`,
    officeProfilingOccupancyTrend: `${environment.miReportUrl}office-profiling-occupancy-trend-type-report`,
    officeProfilingGradeWise: `${environment.miReportUrl}office-profiling-grade-wise-occupancy-report`,
    officeProfilingShareCategory: `${environment.miReportUrl}office-profiling-share-category-report`,
    officeProfilingTenantArea: `${environment.miReportUrl}office-profiling-tenant-leasable-area-report`,
    mallTenantLeaseRateProType: `${environment.miReportUrl}mall-tenant-lease-rate-property-type-report`,
    retailFnbTotalReport: `${environment.miReportUrl}retail-fnb-total-report`,
    retailFnbAverageLeaseCategory: `${environment.miReportUrl}retail-fnb-average-lease-category-report`,
    retailFnbTrendAreaOccupied: `${environment.miReportUrl}retail-fnb-trend-area-occupied-tenant-report`,
    retailFnbCuisineWise: `${environment.miReportUrl}retail-fnb-cuisine-wise-split-area-report`,
    retailFnbCategoryWise: `${environment.miReportUrl}retail-fnb-category-wise-report`,
    officeEmpTotal: `${environment.miReportUrl}office-employment-total-report`,
    officeEmp: `${environment.miReportUrl}office-employment-report`,
    officeEmpDemand: `${environment.miReportUrl}office-total-ofc-demand-report`,
    officeEmpBrkDemand: `${environment.miReportUrl}office-breakup-ofc-demand-report`,
    officeEmpSectorWise: `${environment.miReportUrl}office-sectorwise-employment-report`,
    landVacancyTotalReport: `${environment.miReportUrl}land-vacancy-total-report`,
    landVacancyZoningShareReport: `${environment.miReportUrl}land-vacancy-zoning-share-report`,
    landVacancyLandUseCountReport: `${environment.miReportUrl}land-vacancy-land-use-count-report`,
    landVacancyNeighBlockCountReport: `${environment.miReportUrl}land-vacancy-neigh-block-count-report`,
    landVacancyLandUseDevlopedRatioReport: `${environment.miReportUrl}land-vacancy-land-use-developed-ratio-report`,
    landVacancyNeighBlockRatioReport: `${environment.miReportUrl}land-vacancy-neigh-block-developed-ratio-report`,
    landVacancyLandUseAreaWiseReport: `${environment.miReportUrl}land-vacancy-land-use-area-wise-report`,
    landVacancyLandUseNeighBlockAreaWiseReport: `${environment.miReportUrl}land-vacancy-neigh-block-area-wise-report`,
    roadDataTotalReports: `${environment.miReportUrl}road-data-total-report`,
    roadDataYearlyTrendReport: `${environment.miReportUrl}road-data-yearly-trend-report`,
    roadDataNeighBlockAreaReport: `${environment.miReportUrl}road-data-neigh-block-area-report`,
    neighBlockCombinedData: `${environment.miReportUrl}data-neigh-block-combined`,
    roadDataCategoryWiseChangeTypeInArea: `${environment.miReportUrl}road-data-category-area-report`,
    roadDataCategoryWiseChangeTypeInLength: `${environment.miReportUrl}road-data-category-length-report`,
    roadDataRoadNameWiseChangeInAreaTwoYears: `${environment.miReportUrl}road-data-roadname-area-twoyear-report`,
    roadDataRoadNameWiseChangeInAreaMultiYear: `${environment.miReportUrl}road-data-roadname-area-twoyear-report`,
    totalLandMasterReport:`${environment.miReportUrl}master-plan-wise-parcel-data`,
    masterPlanCountLandParcels:`${environment.miReportUrl}master-plan-wise-count-of-parcel`,
    masterPlanAreaLandParcels:`${environment.miReportUrl}master-plan-wise-area-of-parcel`,
    landDevelopmentRatio:`${environment.miReportUrl}master-plan-wise-land-development-ratio`,
    underConstructionTotal:`${environment.miReportUrl}under-construction-total-report`,
    underConstructionNeighpropery:`${environment.miReportUrl}under-construction-neigh-property-report`
  },
  miEndPoints: {
    requestAccount: `${environment.miUrl}request-account`,
    userActivity: `${environment.miUrl}user-activity`,
    contactUs: `${environment.miUrl}contact-us`,
    userSubscriptionPlan: `${environment.miUrl}user-subscription-plan`,
    subScriptionRequest: `${environment.miUrl}subscription-request`,
    modifySubScriptionRequest: `${environment.miUrl}modify-subscription-request`,
    userSettings: `${environment.miUrl}user-settings`,
    dashboardReports: `${environment.miReportUrl}dashboard-reports`,
    getdashboardReports: `${environment.miReportUrl}dashboard-reports`,
    dashboardUIcomponent: `${environment.miUrl}dashboard-ui-component`,
    MyActivity: `${environment.miUrl}user-dashboard-count`,
    mostUsedQuery: `${environment.miUrl}user-dashboard-query`,
    userViewObjective :`${environment.miUrl}user-dashboard-objective`,
    getdashboardLayout: `${environment.miUrl}dashboard-layout`,
    updatedashboardLayout: `${environment.miUrl}dashboard-layout`,
    getRedisKeys: `${environment.miUrl}redis-keys`,
    deleteRedis: `${environment.miUrl}redis-delete-key`,
    getRequestedUser: `${environment.miUrl}request-account`,
    signUp: `${environment.miUrl}signup`,
    userPreference: `${environment.miReportUrl}user-preference`,
    masterValue: `master-values`
  },
  mapEndPoint:{
    properties : `${environment.miReportUrl}property-map-detail`,
    masterPlanMapDetail : `${environment.miReportUrl}master-plan-report-map-detail`,
    roadDataMapDetails: `${environment.miReportUrl}road-data-report-map-detail`,
    landVacancyDetails:`${environment.miReportUrl}landvacancy-map-detail`,
    masterPlanCompareDetail:`${environment.miReportUrl}master-plan-compare-detail`,
    underConstructionReportMap : `${environment.miReportUrl}under-construction-report-map-detail`
  }
};