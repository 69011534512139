import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { SubjectService } from '../services/subject.service';
import { environment } from '../../environments/environment';
import { BrowserService } from './browser.service';
import { isPlatformBrowser } from '@angular/common';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Subject, Subscription, throwError } from 'rxjs';
import { apiEndPoints } from '../shared/api-endpoint';
import { Observable } from 'rxjs';
import { AlertService } from './alert.service';
import { actionIdConstants } from '../shared/common.constants';
import { forkJoin } from 'rxjs';  // RxJS 6 syntax


@Injectable()
export class ApiService {
  public httpOptions;
  public status;
  public device: any;
  public ip: any;
  public user_id: any = null;
  public user_name: string;
  public user_token: string = '';
  public city_id: any = 'null';
  public session_id: string = 'null';
  public language: number = 1;
  public watcher: Subscription;
  public language_list: any = [];
  public property_type_list = [];
  public entity_type_list = [];
  public allowResources = [];
  public city_code = '';
  public umsUrl = null;
  public miUrl = null;
  public miReportUrl = null;
  public geoestater: string = '';
  private API_KEY = '7kjghhjj';
  public place_id = '';
  setUserLocationData;
  public userSubscription: any = [];
  public app_id: any = '12';
  public cityList: any = [];
  mobile: boolean;
  mobileview: boolean;
  language_code: string = 'en';
  resourceData = new Subject<any>();
  showpdfIcon = new Subject<any>();
  location: Location;

  // set url of api
  setApiUrl() {
    this.miUrl = environment.miUrl;
    this.miReportUrl = environment.miReportUrl;
    this.umsUrl = environment.umsUrl;
    this.user_token =
      this.user_token == '' ? environment.user_token : this.user_token;
    this.geoestater = environment.geoestater;
  }

  constructor(
    private http: HttpClient,
    private route: Router,
    private subject: SubjectService,
    private browser: BrowserService,
    private media: MediaObserver,
    private alert: AlertService,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.location = window.location;
    }
    // this.media.asObservable().pipe(map((change: MediaChange[]) =>{
    //   if (change[0].mqAlias == 'sm') {
    //     this.mobile = true;
    //   }
    //   if (change[0].mqAlias == 'xs') {
    //     this.mobile = true;
    //   } else {
    //     this.mobileview = false;
    //   }
    // }));
    this.setApiUrl();
    this.getIpAddress();
    if (this.browser.getLocalValue('language_id')) {
      this.language = +this.browser.getLocalValue('language_id');
    }
    if (this.browser.getLocalValue('language')) {
      this.language_code = this.browser.getLocalValue('language');
    }
    if (this.browser.getLocalValue('place_id'))
      this.place_id = this.browser.getLocalValue('place_id');
    if (this.browser.getLocalValue('user_id'))
      this.user_id = this.browser.getLocalValue('user_id');
    if (this.browser.getLocalValue('user_token'))
      this.user_token = this.browser.getLocalValue('user_token');
    // this.user_name = this.browser.getLocalValue('user_name');
    if (this.user_id && this.cityList.length === 0) {
      this.getCityList();
    }
    this.getLanguageList();
    if (this.place_id) {
      this.getUserRole();
    }

    // this.getAllowResources();
  }

  // my external ip
  getIpAddress() {
    this.http
      .get('https://api.ipify.org/?format=json')
      .subscribe((data: any) => {
        let ip_details: any = data;
        this.ip = ip_details.ip;
        this.browser.setLocalValue('ip', this.ip);
      });
  }

  // setting header for UMS
  setUmsHeader(content_type = 'application/json') {
    // let dInfo = this.device.browser + ' ' + this.device.browser_version + ' (' + this.device.os + ')';
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', content_type);
    headers = headers.append('app-id', this.app_id);
    // headers = headers.append('User-Device', dInfo);
    headers = headers.append('user-lang', this.language_code);
    //headers = headers.append('session-id', this.session_id);
    headers = headers.append(
      'city-id',
      this.place_id ? this.place_id.toString() : ''
    );
    // if (this.browser.getLocalValue('ip')) {
    //   headers = headers.append('User-Ip', "abcd");
    // } else {
    //   headers = headers.append('User-Ip', '::');
    // }

    headers = headers.append('user-token', this.user_token);
    this.httpOptions = { headers: headers };
  }

  // setting header for mi
  setMiHeader() {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('API-KEY', environment.api_key);
    headers = headers.append(
      'USER-LANG',
      this.language ? this.language.toString() : '1'
    );
    headers = headers.append('USER-CITY', String(this.place_id) || '');
    headers = headers.append('USER-TOKEN', this.user_token);
    // headers = headers.append('session-id', this.session_id);
    this.httpOptions = { headers: headers };
  }
  //Setting header for file upload
  setUploadHeader() {
    let headers = new HttpHeaders();
    headers = headers.append('API-KEY', this.API_KEY);
    headers = headers.append(
      'USER-LANGUAGE',
      this.language ? this.language.toString() : '1'
    );
    headers = headers.append('USER-CITY', this.place_id);
    headers = headers.append('USER-TOKEN', this.user_token);
    // headers = headers.append('session-id', this.session_id);
    this.httpOptions = { headers: headers };
  }

  //Setting header for file upload
  //Setting header for file upload
  setUploadHeaderUms(content_type = 'multipart/form-data', response_type) {
    let headers = new HttpHeaders();
    headers = headers.append('city-id', this.place_id);
    headers = headers.append('USER-CITY', this.place_id);
    headers = headers.append('CITY-CODE', this.city_code ? this.city_code : '');
    headers = headers.append('user-token', this.user_token);
    headers = headers.append('app-id', this.app_id);
    headers = headers.append('user-lang', this.language_code);
    this.httpOptions = { headers: headers };
    if (response_type) {
      this.httpOptions['responseType'] = response_type;
      //headers = headers.append('responseType', response_type);
    }
  }
  setUploadHeaderMi() {
    let headers = new HttpHeaders();
    headers = headers.append('USER-TOKEN', this.user_token);
    headers = headers.append('API-KEY', environment.api_key);
    headers = headers.append('USER-CITY', this.place_id);
    headers = headers.append(
      'USER-LANG',
      this.language ? this.language.toString() : '1'
    );
    this.httpOptions = { headers: headers };
  }

  // get record using this function
  getUmsData(url) {
    this.setUmsHeader();
    return this.http.get(this.umsUrl + url, this.httpOptions);
    // .map(data => data.json());
  }

  // get record of mi
  getMiData(url) {
    this.setMiHeader();
    return this.http.get(this.miUrl + url, this.httpOptions);
    // .map(data => data.json());
  }
  getMiReportData(url) {
    this.setMiHeader();
    return this.http.get(this.miReportUrl + url, this.httpOptions);
    // .map(data => data.json());
  }

  // post record of mi
  postMiData(url, body) {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling Api.'
      );
    }
    this.setMiHeader();
    return this.http.post(this.miUrl + url, body, this.httpOptions);
  }
  // post record of mi
  postMiReportData(url, body) {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling Api.'
      );
    }
    this.setMiHeader();
    return this.http.post(this.miReportUrl + url, body, this.httpOptions);
  }
  // patch record of mi
  patchMiData(url, body) {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling Api.'
      );
    }
    this.setMiHeader();
    return this.http.patch(this.miUrl + url, body, this.httpOptions);
  }
  patchMiReportData(url, body) {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling Api.'
      );
    }
    this.setMiHeader();
    return this.http.patch(this.miReportUrl + url, body, this.httpOptions);
  }
  deleteMiData(url) {
    this.setMiHeader();
    return this.http.delete(this.miUrl + url, this.httpOptions);
  }
  deleteMiReportData(url) {
    this.setMiHeader();
    return this.http.delete(this.miReportUrl + url, this.httpOptions);
  }

  //upload file
  postMiFile(url, body) {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling Api.'
      );
    }
    this.setUploadHeaderMi();
    return this.http.post(this.miUrl + url, body, this.httpOptions);
  }

  postUmsFile(url, body, response_type = null) {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling Api.'
      );
    }
    this.setUploadHeaderUms('application/json', response_type);
    return this.http.post(this.umsUrl + url, body, this.httpOptions);
  }

  // send a POST request to the API to create a data object
  postUmsData(url, body) {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling Api.'
      );
    }
    this.setUmsHeader();
    return this.http.post(this.umsUrl + url, body, this.httpOptions);
  }

  // send a PATCH request to the API to update a data object
  patchUmsData(urlWithId, body) {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling Api.'
      );
    }
    this.setUmsHeader();
    return this.http.patch(this.umsUrl + urlWithId, body, this.httpOptions);
  }

  // send a DELETE request to the API to delete a data object
  deleteUmsData(urlWithId) {
    this.setUmsHeader();
    return this.http.delete(this.umsUrl + urlWithId, this.httpOptions);
  }

  // getting list of property type
  getLanguageList() {
    this.language_list = [];
    this.getUmsData('language').subscribe((res: any) => {
      if (res && res.data) {
        this.language_list = res.data;
        // res.data.forEach((element) => {
        //   if (element) {
        //     this.language_list.push(element);
        //   }
        // });
        this.subject.langList.next(this.language_list);
        this.browser.setLocalValue(
          'lang_list',
          JSON.stringify(this.language_list)
        );
      }
    });
  }

  getCityList() {
    let url = `place`;
    this.cityList = [];
    this.getUmsData(url).subscribe((res: any) => {
      if (res && res.status == 200) {
        let client_host = this.location.hostname;
          let mainCityList:any=[];
          res.data.map((city: any) => {
              var temp = new Array();
              var str = city.domain ? city.domain : '';
              temp = str ? str.split(',') : [];
              if (city.status == 1 && temp.indexOf(client_host) > -1) {
                mainCityList.push(city);
              }
          });
        this.cityList = mainCityList;
        this.browser.setLocalValue('city_list', JSON.stringify(mainCityList));
        this.subject.cityList.next(mainCityList);
      } else if (res.status == 403) {
        this.cityList = [];
        this.logout(false);
        // this.alert.error(res.status);
      }
    });
  }

  // getting allow resources
  getAllowResources() {
    let body = `user/resource?place_id=${this.place_id}&user_id=${this.user_id}`;
    this.getUmsData(body).subscribe(
      (res: any) => {
        // if (res.status == 403 || res.status == 0) {
          // this.logout(false);
          // window.location.reload();
        // }
        this.allowResources = res.data;
        this.resourceData.next(res.data);
      },
      (err) => {
        if (
          err['status'] == 403 ||
          err.message.includes('Invalid user token') ||
          err['status'] == 0
        ) {
          // log out when unauthorised
          this.logout(false);
          // window.location.reload();
        }
      }
    );
  }

  logout(val?, previousUrl = null) {
    const data = {
      user_id: this.user_id,
      place_id: this.place_id,
    }
    const activity = {
      userdata: data,
      actionId: actionIdConstants.User_Logout,
      entity: 'logout',
      entityId: Number(this.user_id)
    }
    this.logUserActivity(activity);
    this.getUmsData('logout?all_device=' + val).subscribe((data) => { });
    this.browser.clearLocalStorage();
    this.browser.deleteCookie('_r_m_');
    this.user_id = null;
    this.place_id = '';
    this.user_token = '';
    this.cityList = [];
    this.subject.cityList.next(null);
    this.subject.userInfo.next(null);
    this.subject.assignedRoles.next(null);
    this.subject.userName.next(null);
    this.subject.profileImage.next(null);
    this.route.navigate(['/'], { queryParams: { returnUrl: previousUrl } });
  }

  async citySelect(cities, from) {
    this.place_id = cities.place_id;
    this.browser.setLocalValue('place_id', cities.place_id);
    this.browser.setLocalValue('city', JSON.stringify(cities));
    try {
      let user_role = await this.getUserRole();
      const activity = {
        entityId: this.user_id,
        userdata: cities,
        actionId: actionIdConstants.User_Login,
        entity: 'user'
      };
      this.logUserActivity(activity);
      this.getAllowResources();
      if (user_role) {
        if (from != 'constructor') {
          if (isPlatformBrowser(this.platformId)) {
            setTimeout(() => {
              window.location.reload();

            }, 500)
          }
        }
      }
    } catch (error) {
    }
  }
  getUserRole() {
    return new Promise((resolve, reject) => {
      this.subject.loader.next(true);
      if(!this.place_id){
        return '';
      }
      let url = `users?user_ids=${this.user_id}&place_id=${this.place_id}`;
      try {
        this.getUmsData(url).subscribe({next: (res: any) => {
          let val = [];
          let roles = '';
          if (res && res.status == 200 && res.data) {
            val = res.data;
            if (val[0].role_names != null || val[0].role_names != undefined) {
              this.subject.loader.next(false);
              roles = val[0].role_names.split(',');
              this.subject.assignedRoles.next(JSON.stringify(roles));
              this.browser.setLocalValue('roles', JSON.stringify(roles));
              resolve(roles);
            } else {
              this.subject.loader.next(false);
              this.alert.error('You are not Authorized to access this Area!');
            }
          } else {
            this.subject.loader.next(false);
            reject(roles);
          }
        },error:(err)=>{
          // this.alert.error(err.error.message);
          this.subject.loader.next(false);
        }});
      } catch (error) {
        reject(error);
      }
    });
  }

  // check admin role
  checkAdminRole() {
    let roles = this.browser.getLocalValue('roles');
    if (roles && typeof roles == 'string') {
      let roleslist: any = JSON.parse(roles);
      return (roleslist.indexOf('admin') > -1)
    }
    return false
  }
  getResult(token) {
    const SECRET_KEY = '6LcrU60UAAAAACWPSjhzScna9MxRym1WiSNzEFky';
    const objeto = { secret: SECRET_KEY, response: token };
    console.warn(`%c token ${token}`, 'color: red;');

    let option = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-Requested-With': 'XMLHttpRequest',
      }),
      params: [{ secret: SECRET_KEY }, { response: token }],
    };
    let ur =
      `https://www.google.com/recaptcha/api/siteverify?secret=` +
      `${SECRET_KEY}&response=${token}`;

    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append('secret', SECRET_KEY);
    urlSearchParams.append('response', token);
    // return this.http.post(this.umsUrl + url, body, this.httpOptions);
    return this.http.post<any>(ur, null);
  }

  getLocalStorageData() {
    return {
      api_key: this.API_KEY || '',
      place_id: this.place_id || '',
      user_token: this.user_token || '',
      language_id: this.language || 1,
    };
  }

  createRequestAccount(value): Observable<any> {
    this.setMiHeader();
    let url = apiEndPoints.miEndPoints.requestAccount;
    const payload = {
      company_name: value.company_name,
      first_name: value.first_name,
      last_name: value.last_name,
      phone_number: value.phone_number,
      business_email: value.business_email,
    };
    return this.http.post<any>(url, payload, this.httpOptions);
  }

  logUserActivity(data) {
    const { reportId, userdata, actionId, entity, entityId } = data;
    if (!actionId) {
      this.alert.error('Action ID is required for activity logging!');
      return;
    }
    let url = `user-activity`;
    let body = {
      action_id: actionId ? actionId : '',
      user_id: this.user_id ? this.user_id : '',
      duration: data?.duration ? data?.duration : 0,
      data: userdata ? userdata : {},
      location: this.getUserLocationData(),
      ip: this.ip ? this.ip : null,
      added_by: this.user_id,
    };
    if (reportId) {
      body['report_id'] = reportId;
    }
    if (entity) {
      body['entity_name'] = entity;
    }
    if (entityId) {
      body['entity_id'] = entityId;
    }
    this.postMiData(url, body).subscribe((res: any) => {
      if (res && res.status == 201) {
      } else {
      }
    });
  }

  getObjective(payload): Observable<any> {
    this.setMiHeader();
    const url = `${apiEndPoints.reportsEndPoints.objectives}?status=1&order_by=sort_order&sort_asc=true`;
    return this.http.get<any>(url, this.httpOptions);
  }

  getGroups(payload): Observable<any> {
    this.setMiHeader();
    const url = `${apiEndPoints.reportsEndPoints.groups}?status=1&sort_by=sort_order&sort_asc=true&objective_id=${payload.objId}`;
    return this.http.get<any>(url, this.httpOptions);
  }

  getReports(payload): Observable<any> {
    this.setMiHeader();
    let params = '';
    if (payload.groupId) {
      params += '&group_id=' + payload.groupId;
    }
    if (payload.id) {
      params += '&id=' + payload.id;
    }

    params += '&user_id=' + this.user_id;
    const url = `${apiEndPoints.reportsEndPoints.reports}?status=1&sort_asc=true&sort_by=sort_order&is_count=false${params}`;
    return this.http.get<any>(url, this.httpOptions);
  }

  getfilterData(
    column_name: string,
    table_name: string,    
    payload: any = null,
    search_text: string = null,
    selected_report_id:number=null
  ): Observable<any> {
    this.setMiHeader();
    let params = '';
    if (payload) {
      let filter = {...payload};
      if(column_name == 'year'){
        delete filter['year'];
      }
      // let removeParm = ['property_category', 'unit_category'];
      // for (let key in payload) {
      //   if (removeParm.indexOf(key) > -1) {
      //     filter[key] = payload[key];
      //   }
      // }
      params = JSON.stringify(filter).replace(/[+]/g, '%2B'); // handle +
    }

    ////Appending reportID in Report APIs
    // let reportID = this.activatedRoute.snapshot.queryParams.rid;
    let reportID = selected_report_id;
    let url = `${apiEndPoints.reportsEndPoints.filterCaseCaddingList}?param=${column_name}&table_name=${table_name}`;

    if (reportID) {
      url += `&report_id=${reportID}`;
    }
    if (search_text) {
      url += `&search_text=${search_text}`;
    }
    
    if (params) url += `&filter=${encodeURIComponent(params)}`;
    return this.http.get<any>(url, this.httpOptions);
  }

  appendFilterParam(payload) {
    let params = new HttpParams();
    if (
      payload?.neigh_name &&
      (payload?.polygon == '' ||
        payload?.polygon == null ||
        payload?.polygon == undefined)
    ) {
      params = params.append('neigh_name', payload?.neigh_name);
    }
    ////Appending reportID in Report APIs
    let reportID = this.activatedRoute.snapshot.queryParams.rid;
    if (reportID) {
      params = params.append('report_id', reportID);
    }

    if (payload?.year) {
      params = params.append('year', payload?.year);
    }
    if (payload?.property_category) {
      params = params.append('property_category', payload?.property_category);
    }
    if (payload?.unit_category) {
      params = params.append('unit_category', payload?.unit_category);
    }

    if (payload?.polygon) {
      params = params.append('polygon', payload?.polygon);
    }

    let filter = {};
    let removeParm = [
      'srid',
      'year',
      'neigh_name',
      'property_category',
      'unit_category',
      'polygon',
      'oid',
      'rid',
      'gid',
    ];
    for (let key in payload) {
      if (removeParm.indexOf(key) == -1) {
        filter[key] = payload[key];
      }
    }
    params = params.append(
      'filter',
      JSON.stringify(filter).replace(/[+]/g, '%2B')
    ); // handle +
    return params;
  }

  setUserLocation() {
    if (isPlatformBrowser(this.platformId)) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            let latlon = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
              user_country: '',
              city: '',
            };
            if (
              this.browser.getCookie('location') &&
              typeof this.browser.getCookie('location') == 'string'
            ) {
              this.setUserLocationData = JSON.parse(
                this.browser.getCookie('location')
              );
            }
            if (!this.setUserLocationData) {
              this.getAddressByLatlon(
                latlon.latitude,
                latlon.longitude
              ).subscribe(
                (res: any) => {
                  if (res.status) {
                    let results = res.results;
                    if (results[0]) {
                      var arrAddress = results;
                      let fullAddr: any = arrAddress[0].address_components;
                      fullAddr.map((element) => {
                        if (element.types.indexOf('neighborhood') > -1) {
                          latlon['neighborhood'] = element.long_name;
                        } else if (element.types.indexOf('locality') > -1) {
                          latlon['city'] = element.long_name;
                        } else if (element.types.indexOf('country') > -1) {
                          latlon['user_country'] = element.long_name;
                        } else if (element.types.indexOf('postal_code') > -1) {
                          latlon['postal_code'] = element.long_name;
                        } else if (
                          element.types.indexOf('administrative_area_level_1') >
                          -1
                        ) {
                          latlon['state'] = element.long_name;
                        } else if (
                          element.types.indexOf('administrative_area_level_2') >
                          -1
                        ) {
                          latlon['area'] = element.long_name;
                        }
                      });
                      this.setUserLocationData = latlon;
                      this.browser.setCookie(
                        'location',
                        JSON.stringify(this.setUserLocationData),
                        1
                      );
                    } else {
                      this.setUserLocationData = latlon;
                    }
                  } else {
                    this.setUserLocationData = latlon;
                  }
                },
                (err) => {
                  this.setUserLocationData = latlon;
                }
              );
            }
          },
          function (error) {
            //this.setUserLocationData = null;
          },
          { enableHighAccuracy: true, timeout: 30000 }
        );
      }
    }
  }

  getAddressByLatlon(lat, lon) {
    return this.http.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lon}&key=${environment.google_key}`
    );
  }

  getUserLocationData() {
    return this.setUserLocationData;
  }

  getDurationInSeconds(startDate, EndDate) {
    const diff = (EndDate.getTime() - startDate.getTime()) / 1000;
    return Math.abs(Math.round(diff));
  }

  contactUs(payload): Observable<any> {
    this.setMiHeader();
    return this.http.post<any>(
      apiEndPoints.miEndPoints.contactUs,
      payload,
      this.httpOptions
    );
  }

  getReportData(payload, url): Observable<any> {
    this.setMiHeader();
    let httpHeaderOptions = { ...this.httpOptions };
    if (payload) {
      httpHeaderOptions['params'] = this.appendFilterParam(payload);
    }
    return this.http.get<any>(url, httpHeaderOptions);
  }

  getServiceExtraPayload(payload, extraPayload, url): Observable<any> {
    this.setMiHeader();
    let httpHeaderOptions = { ...this.httpOptions };
    if (payload) {
      let params = this.appendFilterParam(payload);
      if (extraPayload) {
        for (var key in extraPayload) {
          params = params.append(key, extraPayload[key]);
        }
      }
      httpHeaderOptions['params'] = params;
    }
    return this.http.get<any>(url, httpHeaderOptions);
  }

  getServiceWithTypePayload(payload, type, url): Observable<any> {
    this.setMiHeader();
    let httpHeaderOptions = { ...this.httpOptions };
    if (payload) {
      let params = this.appendFilterParam(payload);
      params = params.append('type', type);
      httpHeaderOptions['params'] = params;
    }
    return this.http.get<any>(url, httpHeaderOptions);
  }

  // get record of mi
  getMIRequest(url) {
    this.setMiHeader();
    return this.http.get(url, this.httpOptions);
  }

  postMiRequest(url, body) {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling Api.'
      );
    }
    this.setMiHeader();
    return this.http.post(url, body, this.httpOptions);
  }

  deleteMiRequest(url) {
    this.setMiHeader();
    return this.http.delete(url, this.httpOptions);
  }

  deleteRedisKeys(url, keys) {
    this.setMiHeader();
    let keysValue = JSON.stringify(keys).replace(/[+]/g, '%2B');
    this.httpOptions['params'] = {
      key: encodeURIComponent(keysValue)
    }
    return this.http.delete(url, this.httpOptions);
  }

  patchMiRequest(url, body) {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling Api.'
      );
    }
    this.setMiHeader();
    return this.http.patch(url, body, this.httpOptions);
  }

  getMultipleRes(url) {
    let placeResponse = this.getUmsData('place');
    let userSetting = this.getMIRequest(url);
    return forkJoin([placeResponse, userSetting]).pipe(
      catchError((error) => throwError('Something went wrong'))
    )
  }

  setUserSettingHeader() {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('APP-ID', '12');
    headers = headers.append('user-lang', this.language_code);
    

    headers = headers.append('USER-TOKEN', this.user_token);
    // headers = headers.append('session-id', this.session_id);
    this.httpOptions = { headers: headers };
  }

  userSetting(url, body) {
    if (body === null || body === undefined) {
      throw new Error(
        'Required parameter body was null or undefined when calling Api.'
      );
    }
    this.setUserSettingHeader();
    return this.http.post(this.umsUrl + url, body, this.httpOptions);
  }

  getUserSetting(url) {
    this.setUserSettingHeader();
    return this.http.get(this.umsUrl + url, this.httpOptions);
    // .map(data => data.json());
  }
}
