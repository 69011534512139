<nav class="navbar header-nav-one navbar-expand-lg navbar-light">
    <a href="{{ec_url}}" target="_blank" class="icon-home">
        <img class="remiEst" src="../../../assets/MIAssets/logo_.png" alt="Brand Logo" />
    </a>
    <a class="justify-content-between pointer city-dropdown" [class.ml-auto]="selectedLangCode != 'ar'" [class.mr-2]="selectedLangCode != 'ar'" [class.mr-auto]="selectedLangCode == 'ar'" [class.ml-2]="selectedLangCode == 'ar'" (click)="citySelect()" [class.city]="getCity()">
        <span class="mr-1 open-sans">
      {{getCity()}}
    </span>
        <span *ngIf="getCity()" class="pr-1 pl-1">
      <svg xmlns="http://www.w3.org/2000/svg" width="12.186" height="6.647" viewBox="0 0 12.186 6.647">
        <path id="XMLID_225_"
          d="M12.025,75.162a.554.554,0,0,0-.783,0L6.094,80.31.946,75.162a.554.554,0,0,0-.783.783L5.7,81.485a.554.554,0,0,0,.783,0l5.539-5.539A.554.554,0,0,0,12.025,75.162Z"
          transform="translate(-0.001 -75)" />
      </svg>
    </span>
    </a>
    <ul class="nav align-items-center mr-2 ml-2" [class.ml-auto]="selectedLangCode != 'ar'" [class.mr-2]="selectedLangCode != 'ar'" [class.mr-auto]="selectedLangCode == 'ar'" [class.ml-2]="selectedLangCode == 'ar'">
        <!-- <li class="nav-item">
            {{'nav.estater_meter' | translate}}
            <ul class="htmlCss-sub-menu sub-menu">
                <li *ngFor="let city of cityList">
                    <a href="{{em_ang}}/{{city.slug}}-{{selectedLangCode}}" target="_blank">{{'nav.explore' | translate}} {{city.place_name}}
            </a>
                </li>
                <li><a href="{{em_ang}}/{{city.slug}}-{{selectedLangCode}}" target="_blank">{{'nav.about_estater_meter' | translate}}</a></li>
            </ul>
        </li> -->
        <li class="nav-item"> 
          <!-- <a href="{{em_ang}}/{{city.slug}}-{{selectedLangCode}}/" class="link_" target="_blank">{{'nav.market_intelligence' | translate}}</a> -->
            <span class="item_city" [class.ml-2]="selectedLangCode != 'ar'" [class.mr-2]="selectedLangCode == 'ar'" *ngIf="user_id && getCity()">
              <span class="city_selection" (click)="citySelect()">
                ({{getCity()}}) <i class="fa fa-angle-down"></i>
              </span>
            </span>
        </li>

        <!-- <li class="nav-item"><a href="{{em_ang}}" class="link_" target="_blank"> {{'nav.about_estater' | translate}}</a></li> -->
        <li class="nav-item notification_icon" *ngIf="assignedRoles?.length > 0">
            <app-notifications></app-notifications>
        </li>
        <ng-container>
            <li class="nav-item lang-nav-item">
                <div class="lang_code">
                    <button class="active" [matMenuTriggerFor]="LangMenu">{{selectedLangCode}} <i class="fa fa-angle-down"></i></button>
                    <mat-menu #LangMenu="matMenu">
                      <div [disabled]="selectedLangCode == language.language_code"
                      *ngFor="let language of langList" class="lang-options" (click)="selectedLanguage(language,true)">
                      {{language.language_code | titlecase}}
                    </div>
                    </mat-menu>
                </div>
            </li>
        </ng-container>
    </ul>
    <div class="login-visibility">
        <a *ngIf="!user_id" [routerLink]="['/']" [attr.href]="!loginDisabled ? '/' : 'JavaScript:void(0)'" class="login d-flex align-items-center" [ngClass]="{disabled: loginDisabled}">
            <img src="../../../assets/MIAssets/icons/user_login.svg" [class]="selectedLangCode =='en' ? 'mr-1' : 'ml-1'" alt="User" style="height:32px">
        </a>
        <a *ngIf="user_id" class="login d-flex align-items-center justify-content-between" [matMenuTriggerFor]="UserMenu">
            <ng-container *ngIf="profileImage && profileImage != ''">
              <span>
                <img class="border rounded user-profile" alt="not" width="34" height="34" src="{{profileImage}}" style="border-radius:50% !important;" />
                <span class="ml-2">{{noProfileName}}</span>
              </span>
              <i class="fa fa-angle-down"></i>
            </ng-container>
            <ng-container *ngIf="!profileImage && profileImage === ''">
              <span>
                <img class="border rounded user-profile" alt="not" width="34" height="34" src="../../../assets/MIAssets/icons/user_login.svg" style="border-radius:50% !important;" />
                <span class="ml-2">{{noProfileName}}</span>
              </span>
              <i class="fa fa-angle-down"></i>
            </ng-container>
        </a>
    </div>
    <mat-menu #UserMenu="matMenu" class="mat-menu-user">
        <ng-template matMenuContent>
            <a mat-menu-item class="user-options" [class.user-options-arb]="selectedLangCode == 'ar'" [routerLink]="[citySlugUrl]">
        {{'User.dashboard' | translate}}
      </a>
            <a class="user-options" [class.user-options-arb]="selectedLangCode == 'ar'" [routerLink]="['/user/profile']" mat-menu-item>
        {{'User.profile' | translate}}
      </a>
            <a class="user-options" [class.user-options-arb]="selectedLangCode == 'ar'" [routerLink]="['/user/plan']" mat-menu-item>
      {{'User.plan' | translate}}
      </a>
            <a class="user-options" [class.user-options-arb]="selectedLangCode == 'ar'" [routerLink]="['/user/my-reports']" mat-menu-item>
        {{'User.report' | translate}}
      </a>
            <a class="user-options" [class.user-options-arb]="selectedLangCode == 'ar'" (click)="logout(false)" mat-menu-item>
        {{'User.logout' | translate}}
      </a>
            <a class="user-options" [class.user-options-arb]="selectedLangCode == 'ar'" (click)="logout(true)" mat-menu-item>
        {{'User.logout_all' | translate}}
      </a>
        </ng-template>
    </mat-menu>

</nav>