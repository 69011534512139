import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MiHomeModule } from "./mi-fe/mi-home.module";
import { ApiService } from './services/api.service';
import { AuthGuard, ReportAuthGuard } from './services/auth.guard';
import { SubjectService } from "./services/subject.service";
import { AlertService } from './services/alert.service';
import { FlexLayoutModule } from "@angular/flex-layout";
import { GlobalService } from './services/global.service';
import { AlertModule } from './services/alert/alert.module';
import { BrowserService } from './services/browser.service';
import { CommonfunctionService } from './services/commonfunction.service';
import { Page404Component } from './page-404';
import { MapComponent } from './user-dashboard/profile/map/map.component';
import { ChangePasswordComponent } from './user-dashboard/profile/change-password/change-password.component';
import { I18nModule } from './i18n/i18n.module';
import { ConfirmDialogBox } from './core/confirm-dialog/confirm-dialogbox';
import { MatModules } from './mat-modules';
import { CoreModule } from './core/core.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import {DecimalPipe} from '@angular/common';
import { InterceptorService } from './common/services/interceptor.service';

@NgModule({
    declarations: [
        AppComponent,
        Page404Component,
        MapComponent,
        ChangePasswordComponent,
        ConfirmDialogBox
        // HeaderSectionComponent,
        // FooterSectionComponent,
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        AppRoutingModule,
        BrowserAnimationsModule,
        ...MatModules,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        MiHomeModule,
        FlexLayoutModule,
        AlertModule,
        I18nModule,
        CoreModule,
        NgxSkeletonLoaderModule,
        NgMultiSelectDropDownModule.forRoot()
    ],
    providers: [
        ApiService, BrowserService, AuthGuard, ReportAuthGuard, SubjectService, AlertService, GlobalService, DecimalPipe,
        CommonfunctionService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptorService,
            multi: true,
          },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
